import { Button, Modal, Input, Form, Select, message, Col, Row, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { requestError } from '../../controllers/functions';

const ParentModal = ({ show, setOpenModal, record, action, refresh }) => {

  const [form] = Form.useForm();
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [state, setState] = useState({});

  useEffect(() => {
    setState({...record});
    form.resetFields();
    form.setFieldsValue({...record})
  }, [show ]);

  if(show !== isModalVisible){
    setIsModalVisible(show);
  }

  const submit = (event) =>{
    axios.post(process.env.REACT_APP_API_URI+"/parents/", state , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenModal(false);
      refresh();
    }).catch(error=>{
      requestError({error});
    }).finally(done=>{
        setLoading(false);
    })
  }

  const edit = (event) =>{
    axios.put(process.env.REACT_APP_API_URI+`/parents/${state?.id}`, state , {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}} ).then(res=>{
      message.success(res.data.res)
      setOpenModal(false);
      refresh();
    }).catch(error=>{
      requestError({error});
    }).finally(done=>{
        setLoading(false);
    })
  }

  const [loading, setLoading] = useState(false)

  const onChange = (event) => {
    setState({ ...state, [event.target.name] : event.target.value });
  }
  

  return (
    <>
    
      <Modal 
      title={`PARENT ${ record?.name ? `: ${record?.name}` : ''}`.toUpperCase() }
      open={isModalVisible} 
      onOk={()=>setOpenModal(false)} 
      onCancel={()=>setOpenModal(false)}
      footer={[
        <Button key="cancel" type=""  onClick={()=>{setOpenModal(false)}}>
            Cancel
        </Button>,
        <Button  key="submit" type="primary" htmlType="submit" form="subjectForm" loading={loading}>
          {action === `create`? `Submit` : `Update`}
        </Button>     
      ]}
      >
      <Form
      layout="vertical"
      className="row-col"
      onFinish={(e)=> {
        setLoading(true);
        if(action === "edit") {
          edit({isDraft : false});
        }else{
          submit({isDraft : false});
        }
      }}
      id="subjectForm" 
      form={form}
      >
        <Row>
          <Col span={14} >
            <Form.Item
            name = "name" 
            style={{marginRight: 5}}
            rules={[
                { required: true, message: "Please input your Parent Name!" },
            ]}>
              <Input  style={{ height : 32 }}  value={state?.name} onChange={onChange} name = "name" placeholder="Parent Name" />
            </Form.Item>
          </Col>
          <Col span={10} >
            <Form.Item
            name = "phone" 
            style={{marginRight: 5}}
            rules={[
                { required: true, message: "Enter your parent phone number)!" },
            ]}>
              <Input  style={{ height : 32 }}  value={state?.phone} onChange={onChange} name = "phone" placeholder="Parent phone number" />
            </Form.Item>
          </Col>
        </Row>
        </Form>


      </Modal>
    </>
  );
};

export default ParentModal;