
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Typography,
    Input,
    Modal
  } from "antd";
  
  import {useEffect, useState} from 'react';
  import axios from 'axios';
  import {  EditOutlined, EyeFilled } from "@ant-design/icons";
  import { requestError } from "../controllers/functions";
import ParentModal from "../components/modals/Parent";
import ParentStudentsModal from "../components/modals/Parent.Students";
    
  // table code start
  const columns = [

    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "25%",
    },
  
    {
      title: "ACTION",
      key: "status",
      dataIndex: "action",
      width: "7%",
    }
  ];
  function ParentsTable({permissions}) {
    const [openRecordModal, setOpenRecordModal] = useState(false);
    const [openParentStudentModal, setOpenParentStudentModal] = useState(false);
    const [records, setRecords] = useState([]);
    const [data, setData] = useState([]);
    const [record, setRecord] = useState(null);
    const [action, setAction] = useState('create');
    const [searchInput, setSearchInput] = useState(null);
  
  
    useEffect(() => {
      refresh();
    }, [permissions]);
  
    const refresh = () => {
      axios.get(process.env.REACT_APP_API_URI+"/parents/", {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
        setData(data.data);
        dataFormatter(data.data);
      }).catch(error =>{
        requestError({error})
      })
    }
  
    const deleteRecord = (id) =>{
      axios.delete(process.env.REACT_APP_API_URI+`/parents/${id}`, {headers: { authorization : `Bearer ${localStorage.getItem("token")}`}}).then(data =>{
        refresh();
      }).catch(error =>{
        requestError({error});
      })
    }
  
    const dataFormatter = (list) => {
      var arrayList = [];
        list.forEach(element => {
          arrayList.push(
            {
              key: element?.id,
     
              name: (
                <>
                  {element?.name}
                </>
              ),
              phone: (
                <>
                  {element?.phone}
                </>
              ),
              action: (
                <>
                
                  {
                    permissions.some(x=>x.permission?.name === "Update_Parent") &&
                    <Button onClick={()=>{setAction('edit'); setRecord(element); setOpenRecordModal(true)}} type="" className="ant-btn-sm m-5"><EditOutlined /> Edit</Button>
                  }

                  {
                    (permissions.some(x=>x.permission?.name === "Get_All_Parent") || permissions.some(x=>x.permission?.name === "Get_One_Parent")) &&
                    <Button onClick={()=>{setAction('edit'); setRecord(element); setOpenParentStudentModal(true)}} type="" className="ant-btn-sm m-5"><EyeFilled /> Students</Button>
                  }
  
                  {
                    permissions.some(x=>x.permission?.name === "Delete_Parent") &&
                    <Button 
                    onClick={()=>{
                      Modal.confirm({
                      title  : "Are you sure delete this parent?",
                      onCancel : ()=>{},
                      onOk :  ()=> deleteRecord(element.id)
                    })}}
                     className="ant-btn-sm m-5" danger>Delete Parent</Button> 
                  }
  
                </>
              ),
            }
          )
        });
        setRecords(arrayList);
    }
  
    const search = (input) => {
  
      var newList = data;

      if ( input ){
          newList = data.filter( x => {
          return (
            x.id === input || 
            x?.name?.toUpperCase().includes(input.toUpperCase()) ||
            x?.phone?.toUpperCase().includes(input.toUpperCase()) 
          )
        })
      }
      
  
      dataFormatter(newList)
  
    }

    useEffect( () => {
      search(searchInput)
    }, [searchInput, data ])
  
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Parents"
                extra={
                  <>
                    <Row>
                      <Col>
                        <Input onChange = {(event)=>{ 
                          setSearchInput(event.target.value); 
                        }}   
                        style={{
                            height : 30
                        }}
                        placeholder="Search Parent..." />
                      </Col>
                      <Col>
                        {
                          permissions.some(x=>x.permission?.name === "Create_Subject") &&
                          <Button 
                          onClick={()=>{setAction(`create`);setOpenRecordModal(true);setRecord({})}} 
                          style={{
                            height : 30
                          }}
                          >ADD PARENT </Button>
                        }
                      </Col>
                      </Row>
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={records}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <ParentModal refresh={refresh} record={record} action={action} setOpenModal = {setOpenRecordModal} show = {openRecordModal} />
        <ParentStudentsModal parent={record}  setOpenModal = {setOpenParentStudentModal} show = {openParentStudentModal} />
      </>
    );
  }
  
  export default ParentsTable;
  