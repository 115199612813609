
import {message } from 'antd'
import axios from 'axios';


const requestError = ({error, showErrorMessage }) => {
  if(error?.response?.status === 401 ) {
    
    window.location = `/?url=${window.location.href}`

  } else if(error?.response?.status === 402 ) {
    
    window.location = `/subscribe`

  }else{
    if(showErrorMessage !== false) {
      message.error(error.response?.data?.res || error.response?.data || error?.message || "Server application not available");
    }
  }
}

export {requestError};

const downloadFile = async ({url}) => {
    try {
        const response = await axios.get(url, {
            responseType: 'blob', // Ensures the response is a file (binary data)
            headers: { authorization : `Bearer ${localStorage.getItem("token")}`}
        });

        // Extract the filename from the Content-Disposition header
        const contentDisposition = response.headers['content-disposition'];
        let filename = ''; // Default filename
        if (contentDisposition) {
            const match = contentDisposition.match(/filename="?([^"]+)"?/);
            if (match && match[1]) {
                filename = match[1];
            }
        }

        // Create a Blob from the response
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary anchor element for triggering download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;

        // Append the link to the document, click it, then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the Blob URL after download to free up resources
        URL.revokeObjectURL(link.href);
    } catch (error) {
        message.error('Failed to download the file. Please try again.')
    }
};


export{downloadFile};


const  getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const queryParams = {};
  for (const [key, value] of params.entries()) {
      queryParams[key] = value;
  }
  return queryParams;
}

export{getQueryParams}